import React, { Component } from 'react';
import { FirebaseContext } from '../context/firebaseContext'
import { HomeBuildingStageOption, LocationOption, FirebaseName, MonthNames, WeekNames, iamOptions } from '../constant/Const'
import $, { contains } from 'jquery';
import select2 from 'select2';
import swal from 'sweetalert'

const defaultState = {
  name: '',
  phoneNumber: '',
  email: '',
  homeBuildingStage: '',
  iam: '',
  location: '',
  slotInfo: {},
  selectedSlot: '',
  agreement: true,
  policy: true,
  error: "",
  isLoading: false,
  slots: null,
  showDates: false,
  done: false,
}
export default class Registration extends Component {
  prefix = React.createRef();
  requirement = React.createRef();
  location = React.createRef();

  state = defaultState;
  componentDidMount() {
    this.getEventSlots();
    window.c = this;

    $(this.prefix.current).select2({ minimumResultsForSearch: -1 });
    $(this.requirement.current).select2({ minimumResultsForSearch: -1 }).on('change', (event) => {
      this.handleChange(event);
    });
    $(this.location.current).select2({ minimumResultsForSearch: -1 }).on('change', (event) => {
      this.handleChange(event);
    });

    // window.addEventListener('orientationchange', () => {
    //   console.log('orientationchange')
    //   this.orientationCheck();
    // })
    // this.orientationCheck();

    // window.addEventListener('resize', () => {
    //   console.log('resize')
    //   this.orientationCheck();
    // })

    // this.getCurrentTimeStampV2();
  }

  turnOnRotation =() =>{
    document.querySelector('#rotate').classList.add('d-flexImp')
    document.querySelector('#rotate').classList.remove('d-noneImp')
  }
  turnOffRotation = () =>{
    document.querySelector('#rotate').classList.remove('d-flexImp')
    document.querySelector('#rotate').classList.add('d-noneImp')
  }

  orientationCheck = () => {
    console.log(window.screen.width, window.innerWidth, window.screen.height, window.innerHeight)
    if (window.screen.width > window.screen.height) {
      this.turnOnRotation();
    }else{
      this.turnOffRotation();
    }
  }

  //#region Firebase Functions
  getUsreInfo = () => {
    let userValues = {}
    userValues.name = this.state.name
    userValues.phoneNumber = this.state.phoneNumber
    userValues.email = this.state.email
    userValues.iam = this.state.iam
    userValues.location = this.state.location
    userValues.slot = this.state.selectedSlot.id
    userValues.slotInfo = this.state.selectedSlot
    userValues.title = this.prefix.current.value;
    return userValues;
  }
  saveUserInfo = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let userValues = this.getUsreInfo();
        userValues.registrationTimeStamp = this.context.firestore.FieldValue.serverTimestamp();
        const Firestore = this.context.firestore();
        const docRef = Firestore.collection(FirebaseName.USERCOLLECTION).doc(userValues.phoneNumber)
        const registerDocRef = Firestore.collection(FirebaseName.SLOTCOLLECTION).doc(`${userValues.slot}`).collection(FirebaseName.SLOTREGISTEREDCOLLECTION).doc(FirebaseName.SLOTREGISTEREDDC)
        const slotDocRef = Firestore.collection(FirebaseName.SLOTCOLLECTION).doc(`${userValues.slot}`)
        await Firestore.runTransaction(async (transcation) => {
          const doc = await transcation.get(docRef);
          if (doc.exists) {
            let error = { code: "UserFound", message: "UserAlreadyRegistered" };
            throw error;
          }
          const registerDoc = await transcation.get(registerDocRef);
          const slotDoc = await transcation.get(slotDocRef);
          let registerDocData = {}
          if (!registerDoc.exists) {
            registerDocData.users = {}
          } else {
            registerDocData = registerDoc.data();
            if (registerDocData.users.hasOwnProperty("key")) {
              let error = { code: "UserRegistedForEvent", message: "UserAlreadyRegisteredForSelectedEvent" };
              throw error;
            }
          }
          if (slotDoc.data().gotFull) {
            let error = { code: "gotFull", message: "Sorry! Registerations got full. Please register yourself in another suitable event slot." };
            throw error;
          }

          registerDocData.users[`${userValues.phoneNumber}`] = {
            id: userValues.phoneNumber,
            name: userValues.name,
            timestamp: this.context.firestore.FieldValue.serverTimestamp()
          }

          if (Object.keys(registerDocData.users).length >= slotDoc.data().registrationLimit) {
            transcation.update(slotDocRef, {
              gotFull: true,
              registeredCount: Object.keys(registerDocData.users).length
            })
          } else {
            transcation.update(slotDocRef, {
              registeredCount: Object.keys(registerDocData.users).length
            })
          }

          transcation.set(docRef, userValues)
          registerDoc.exists ? transcation.update(registerDocRef, registerDocData) : transcation.set(registerDocRef, registerDocData)
          return
        })
        console.log("registered!!")
        resolve();
      }
      catch (err) {
        reject(err);
      }
    })
  }

  getCurrentTimeStamp = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await fetch("https://worldtimeapi.org/api/timezone/Etc/UTC");
        const result = await res.json()
        if (result && result.datetime) {
          var currDateWeb = new Date(result.datetime);
          currDateWeb = currDateWeb.getTime();
          resolve(currDateWeb)
        }
      } catch (err) {
        reject(err)
      }
    })
  }

  getCurrentTimeStampV2 = () => {
    return new Promise(async (resolve, reject) => {
      try {

        const res = await fetch("https://dj-timeserver.glitch.me");
        // const res = await fetch("https://dj-wtimesever.glitch.me/");
        const result = await res.json()
        var currDateWeb = new Date(result.epoch);
        currDateWeb = currDateWeb.getTime();
        resolve(currDateWeb)
      } catch (err) {
        reject(err)
      }
    })
  }

  getEventSlots = async () => {
    // const currentTimestamp = await this.getCurrentTimeStamp();
    const currentTimestamp = await this.getCurrentTimeStampV2();
    const Firestore = this.context.firestore();
    const docRef = Firestore.collection(FirebaseName.SLOTCOLLECTION).where('dateTimestamp', ">=", currentTimestamp).limit(18)
    await docRef.onSnapshot((query) => {
      if (query.empty) {
        console.error("no slots found!!")
      }
      let slotInfo = {}
      query.docs.map((doc) => {
        let data = doc.data();
        if (!slotInfo[`${data.dateTimestamp}`]) {
          slotInfo[`${data.dateTimestamp}`] = {}
          let currentDate = new Date(data.dateTimestamp);
          slotInfo[`${data.dateTimestamp}`].date = `${MonthNames[currentDate.getMonth()]} ${currentDate.getDate()}`
          slotInfo[`${data.dateTimestamp}`].day = `${WeekNames[currentDate.getDay()]}`
        }
        if (!slotInfo[`${data.dateTimestamp}`].data) {
          slotInfo[`${data.dateTimestamp}`].data = {}
        }
        let slotTime = new Date(data.eventStartTime)
        slotTime = slotTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })
        slotInfo[`${data.dateTimestamp}`].data[`${data.eventStartTime}`] =
        {
          isVisible: data.dayActive ? data.active : false,
          gotFull: data.gotFull,
          slotTime: slotTime,
          id: data.id,
        }

        if (Object.keys(slotInfo[`${data.dateTimestamp}`].data).length === 3) {
          let disbaleCount = 0;
          Object.keys(slotInfo[`${data.dateTimestamp}`].data).forEach(key => {
            if (!slotInfo[`${data.dateTimestamp}`].data[key].isVisible) {
              disbaleCount++;
            }
          })
          if (disbaleCount === 3) {
            delete slotInfo[`${data.dateTimestamp}`];
          }
        }

      })

      this.setState({
        slots: slotInfo
      })

    }, (err) => {
      console.log(err);
    });

  }
  //#endregion

  //#region UserValidation Fucntion
  validateEmail = (email) => {
    // eslint-disable-next-line
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  inputValidation = () => {
    let result = {
      status: false,
      message: ""
    }
    if (this.state.name === '') {
      result.message = "Please enter name field correctly"
      return result;
    } else if (this.state.phoneNumber === '' || this.state.phoneNumber.length !== 10) {
      result.message = "Please enter phone number correctly."
      return result;
    } else if (this.state.iam === '' || this.state.location === '') {
      result.message = "Please select a valid field from dropdown."
      return result;
    } else if (this.state.selectedSlot === '') {
      result.message = "Please select a slot for list."
      return result;
    }

    if (this.state.email !== '') {
      if (!this.validateEmail(this.state.email)) {
        result.message = "Please enter a valid Email Id."
        return result;
      }
    }
    //todo add slot verification
    result.status = true;
    return result;
  }
  //#endregion

  //#region Form Function
  handleSubmit = async (event) => {
    try {
      event.preventDefault();
      if (window.gtag_report_conversion) {
        window.gtag_report_conversion()
    }
    if (window.fbq) {
        window.fbq('track', 'Lead_BGK')
    }
      if (this.state.isLoading) {
        return;
      }
      this.setState({
        isLoading: true,
        agreement: true,
      })

      let validation = this.inputValidation()
      if (!validation.status) {
        throw validation
      }
      await this.saveUserInfo();
      //show some successful message
      let newState = { ...defaultState, slots: this.state.slots, slotInfo: {}, done: true };
      this.setState(newState)
    } catch (err) {
      console.log(err)
      this.setState({
        error: err.message,
        isLoading: false
      })
    }
  }

  handleChange = (event) => {
    event.preventDefault();
    const target = event.target;
    let value = target.value
    if (value) {
      if (event.target.name === 'phoneNumber') {
        value = value.substring(0, 10)
      }
    }
    this.setState({ [target.name]: target.type === 'checkbox' ? target.checked : value });
  }

  handleSlotChange = (event) => {
    event.preventDefault();
    const target = event.target;
    let oldSlotInfo = this.state.slotInfo;
    if (target.checked) {
      Object.keys(oldSlotInfo).forEach(value => {
        oldSlotInfo[value] = false;
      })
    }
    oldSlotInfo[target.name] = target.checked
    let selectedSlot = ''
    if (target.checked) {
      let arr = target.id.split(',');
      selectedSlot = {}
      selectedSlot.id = arr[0];
      selectedSlot.date = arr[1];
      selectedSlot.time = arr[2];
    }
    this.setState({
      slotInfo: oldSlotInfo,
      selectedSlot: selectedSlot,
      showDates: false
    })
  }
  //#endregion


  render() {
    return (
      <>
        {/* <div className="rotate-screen-popup">
          <div id="rotate">
            <div className="phone">
            </div>
            <div className="message">
              Please rotate your device!
              </div>
          </div>
        </div> */}

        <section className="landing-page min-height-full">
          <aside className="landing-pageBox d-flex justify-content-between align-items-start min-height-full image-bg"
            style={{ backgroundImage: 'url(assets/images/bg/home-bg.jpg)' }}>
            <a href="#" className="landing-pageBox__logo">
              <img src="assets/images/logo.png" alt="Ultra Tech" style={{ width: '5.5rem' }} />
            </a>
            <img src="assets/images/side-pic.png" alt="Ultra Tech" className="landing-pageBox__banner" />
            {/* <div className="claim-footer">
              ‘UltraTech, India’s No. 1 Cement’ - visit ultratechcement.com for claim details.
            </div> */}
          </aside>
          <aside className="signinBox min-height-full">
            <h1 className="signinBox__heading">Registration</h1>
            <div className="signinBox__body">
              {
                this.state.done &&
                <div class="successful-registration">
                  <p class="font-25">Thank you for registering.<br></br> Please use the registered name and mobile number as Log In credentials at the selected date/time of the Shivir.</p>

                  <div className="slide-item-borderContainer align-items-center mg-t80 mg-2" style={{ marginBottom: '10rem' }}>
                    <img className="slide-item-borderContainer-middleImage" src="/assets/images/img2.png" alt=""></img>
                    <p className="mg-t40 mg-lg-t60 pd-1 text-center">
                      <strong> #BaatGharKi Shivir </strong> is accessible through laptop/desktop, smartphones, and tablets.
                        </p>
                    <p className="mg-t15 mg-b10 pd-1 text-center">
                      Use laptop/desktop for a better experience.
                        </p>
                    <p className="mg-t15 mg-b10 pd-1 text-center">
                      For an optimum experience of the Shivir, use these browsers —<br></br>
                      Laptop: Google Chrome, Safari, Microsoft Edge<br></br>
                      Mobile: Google Chrome, Safari
                      </p>

                    <div className="line"></div>
                    <p className="mg-t30 mg-lg-t60 pd-1 text-center g-t hindi-text">
                      आप लैपटॉप / डेस्कटॉप, मोबाइल और टैबलेट के माध्यम से बात घर की शिविर में हिस्सा ले सकते हैं।
                        </p>
                    <p className="mg-t15 mg-b10 pd-1 text-center g-t hindi-text">
                      बेहतर अनुभव के लिए लैपटॉप / डेस्कटॉप का उपयोग करें
                        </p>
                    <p className="mg-t15 mg-b10 pd-1 text-center g-t hindi-text">
                      शिविर के सवोत्तम अनुभव के लिए इन ब्राउज़र का उपयोग करें -<br></br>
                      लैपटॉप: गूगल क्रोम, सफारी, माइक्रोसॉफ्ट एज<br></br>
                      मोबाइल: गूगल क्रोम, सफारी
                      </p>
                  </div>

                </div>
              }
              {
                !this.state.done &&
                <form onSubmit={this.handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
                  <div className="form-group has-form-control__select">
                    {/* Enter your Name */}
                    <input type="text" value={this.state.name} onChange={this.handleChange} name="name" className="form-control" placeholder="*अपना नाम लिखें" required />
                    <div className="form-control__select">
                      <select ref={this.prefix} className="custom-select2 js-example-basic-single">
                        <option defaultValue>Mr.</option>
                        <option>Mr.</option>
                        <option>Mrs.</option>
                        <option>Ms.</option>
                        <option>Others</option>
                      </select>
                    </div>
                  </div>
                  <div className="form-group has-form-control__select">
                    {/* Enter your Phone no. */}
                    <input type="number" value={this.state.phoneNumber} onChange={this.handleChange} name="phoneNumber" className="form-control" placeholder="*अपना मोबाइल नंबर लिखें" />
                    <input type="text" className="form-control__text" defaultValue="+91" />
                  </div>
                  {/* <div className="form-group">
                    <input type="text" className="form-control" value={this.state.email} onChange={this.handleChange} name="email" placeholder="Enter your Email ID(optional)" />
                  </div> */}
                  {/* <div className="form-group">
                    <select ref={this.requirement} className="custom-select2 js-example-basic-single" value={this.state.homeBuildingStage} name="homeBuildingStage" >
                      {
                        HomeBuildingStageOption.map((element, index) => { return (<option value={index === 0 ? '' : element} key={element}>{element}</option>) })
                      }
                    </select>
                  </div> */}
                  <div className="form-group">
                    <select ref={this.requirement} className="custom-select2 js-example-basic-single" value={this.state.iam} name="iam" >
                      {
                        iamOptions.map((element, index) => { return (<option value={index === 0 ? '' : element} key={element}>{element}</option>) })
                      }
                    </select>
                  </div>

                  <div className="form-group">
                    <select ref={this.location} className="custom-select2 js-example-basic-single" value={this.state.location} name="location" >
                      {LocationOption.map((element, index) => <option value={index === 0 ? '' : element} key={element}>{element}</option>)}
                    </select>
                  </div>
                  <div className="form-group">
                    <div className={`select-dropdown ${this.state.showDates ? 'open' : ''}`}>
                      <div className="select-dropdown__header">
                        <p className="select-dropdown__left">Date</p>
                        <p className="select-dropdown__right">Slots (2 hrs slots each)</p>
                      </div>
                      <div className="select-dropdown__body">
                        {
                          (this.state.slots) ? (
                            Object.keys(this.state.slots).map(key =>
                              (
                                <div key={key} className="select-dropdown__body-item">
                                  <p key={key + "--"} className="select-dropdown__left">{this.state.slots[key].date}<small>{this.state.slots[key].day}</small></p>
                                  <ul className="select-dropdown__right">
                                    {
                                      this.state.slots[key].data &&
                                      Object.keys(this.state.slots[key].data).map(date => (
                                        <>{
                                          this.state.slots[key].data[date].isVisible &&
                                          <li key={`${this.state.slots[key].data[date].id}`}>
                                            <label className="custom-radio"><span className="custom-radio__title">{this.state.slots[key].data[date].slotTime}</span>
                                              <input key={Math.random()}
                                                type="checkbox"
                                                disabled={this.state.slots[key].data[date].gotFull}
                                                checked={this.state.slotInfo[`${date}`] !== undefined ? this.state.slotInfo[`${date}`] : false}
                                                onChange={this.handleSlotChange}
                                                name={`${date}`}
                                                id={`${this.state.slots[key].data[date].id},${this.state.slots[key].date},${this.state.slots[key].data[date].slotTime}`} />
                                              <span className="custom-radio__icon"></span>
                                            </label>
                                          </li>
                                        }
                                        </>
                                      ))
                                    }
                                  </ul>
                                </div>
                              )
                            )
                          ) :
                            (<div className="select-dropdown__body-item">
                              <img src="/assets/images/loader.gif" alt="loader" style={{ width: '5rem', height: '5rem' }}></img>
                            </div>)
                        }
                      </div>
                    </div>
                    <div className="select-control" onClick={() => this.setState({ showDates: !this.state.showDates })} >{this.state.selectedSlot ? this.state.selectedSlot.date + " " + this.state.selectedSlot.time : '*Select any one Slot'}</div>
                  </div>
                  <p className="font-20 mg-b20">*These are mandatory fields.</p>

                  <div className="form-group">
                    <label className="custom-radio">
                      <input key={Math.random()} type="checkbox" checked={this.state.agreement} onChange={this.handleChange} name="agreement" />
                      <span className="custom-radio__icon"></span>
                      <span className="custom-radio__title">  I want UltraTech to get in touch with me for homebuilding related information through call, mail, and SMS.</span>
                    </label>
                  </div>

                  <div className="form-group" style={{ marginBottom: '1rem' }}>
                    <label className="custom-radio">
                      <input key={Math.random()} type="checkbox" checked={this.state.policy} onChange={this.handleChange} name="policy" />
                      <span className="custom-radio__icon"></span>
                      <span className="custom-radio__title"> By submitting the above information, you accept that you have read, understood, and agree to our <a href="/assets/pdf/disclaimer.pdf" target="_blank">Disclaimer</a> and <a href="/assets/pdf/policy.pdf" target="_blank">Privacy Policy</a>.</span>
                    </label>
                  </div>
                  <div className="form-group">
                    {this.state.error ? (
                      <span className="error">{this.state.error}</span>
                    ) : null}
                  </div>

                  {/* 
                  <div className="form-group">
                    <label className="custom-radio">
                      <input key={Math.random()} type="checkbox" checked={this.state.agreement} onChange={this.handleChange} name="agreement" />
                      <span className="custom-radio__icon"></span>
                      <span className="custom-radio__title">  I want UltraTech to get in touch with me for homebuilding related information through call, mail, and SMS.</span>
                    </label>
                  </div>


                  <div className="form-group">
                    <label className="custom-radio">
                      <input key={Math.random()} type="checkbox" checked={this.state.policy} onChange={this.handleChange} name="policy" />
                      <span className="custom-radio__icon"></span>
                      <span className="custom-radio__title">  I agree to Terms and Policies.</span>
                    </label>

                  </div>
                  
                  <div className="form-group">
                    <p>Please click here to read <a href="/assets/pdf/terms.pdf" target="_blank">Terms and Policies</a>.</p>
                    {this.state.error ? (
                      <span className="error">{this.state.error}</span>
                    ) : null}
                  </div> */}

                  <div className="text-center" style={{ marginBottom: '10rem' }}>
                    <button className="btn btn-lg btn-dark" type="submit" disabled={this.state.isLoading ? true : !(this.state.agreement && this.state.policy)}>
                      <span className="d-flex justify-content-between"> REGISTER <i className="icon-angle-right"></i> </span>
                    </button>
                  </div>
                </form>
              }
            </div>
          </aside>
        </section>
      </>
    );
  }
}
Registration.contextType = FirebaseContext;
