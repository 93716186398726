import React from 'react';
import Application from "./components/Registration";
import FirebaseProvider from "./context/firebaseContext";



export default class App extends React.Component {

  render() {
    return (
        <FirebaseProvider>
          <Application/>
        </FirebaseProvider>
    );
  }
}
