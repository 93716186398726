
export const HomeBuildingStageOption = ['Stage of Homebuilding','I am yet to buy a plot of land.', 'I have purchased land, planning to begin construction.', 'I have begun construction of my new home.', 'I hace recently finished constructing my home.'];
// export const LocationOption = ["Select Your Location", "Andaman and Nicobar Islands", "Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chandigarh", "Chhattisgarh", "Dadra and Nagar Haveli", "Daman and Diu", "Delhi", "Goa", "Gujarat",
// "Haryana", "Himachal Pradesh", "Jammu and Kashmir", "Jharkhand", "Karnataka", "Kerala", "Lakshadweep", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha",
// "Puducherry", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttarakhand", "Uttar Pradesh", "West Bengal", "Other"];

export const LocationOption = ["Select Your Location", "Jalandhar", "Kapurthala", "Paghwara"];


export const FirebaseName = {
    USERCOLLECTION: 'userData',
    SLOTCOLLECTION: 'eventSlots',
    SLOTREGISTEREDCOLLECTION: 'registeredUsers',
    SLOTREGISTEREDDC: 'usersData',
}

export const MonthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];
export const WeekNames = ["Sunday","Monday", "Tuesday", "Wednesday", "Thrusday", "Friday", "Saturday"]

export const iamOptions = ['I am', 'गृह निर्माता', 'शिल्पकार (मिस्त्री) / ठेकेदार (कॉन्ट्रैक्टर)', 'सीमेंट विक्रेता']