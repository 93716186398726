import React, { Component } from 'react';
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAbEeJ9k-l-slkL-rrsttgQZpKX714-kZA",
    authDomain: "ultratech-event.firebaseapp.com",
    databaseURL: "https://ultratech-event.firebaseio.com",
    projectId: "ultratech-event",
    storageBucket: "ultratech-event.appspot.com",
    messagingSenderId: "494885983528",
    appId: "1:494885983528:web:676134714d71870f26ec1d",
    measurementId: "G-J6TY81CNPJ"
};
firebase.initializeApp(firebaseConfig);
export const firebaseApp = firebase;
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const analytics = firebase.analytics();


export const FirebaseContext = React.createContext({});

export default class FirebaseProvider extends Component {
    render() {
        return (
            <FirebaseContext.Provider value={firebase}>
                {this.props.children}
            </FirebaseContext.Provider>
        );
    }
}
